

import { useSelector } from 'react-redux';

import { FunctionalRootState } from '@share/utils';
import { IDashboardReportState } from '@store/slices';

import { DashboardReportHeaderItem, HeaderItemColor, HeaderItemType } from './dashboard-report-header-item';

import { faArrowTrendDown, faArrowTrendUp, faDollarSign, faPiggyBank } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export function DashboardReportHeader() {
  const { dashBoardData }: IDashboardReportState = useSelector((state: FunctionalRootState) => state.dashboardReportsStore);

  return (
    <div className="dashboard-report-header-wrapper">
      <DashboardReportHeaderItem type={HeaderItemType.NUMBER} title={'bookings'} icon={faArrowTrendUp} value={dashBoardData?.confirmedBookings} color={HeaderItemColor.BLUE} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} title={'sales'} icon={faDollarSign} value={dashBoardData?.sales} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} title={'savings'} icon={faDollarSign} value={dashBoardData?.savings} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} title={'commissions'} icon={faPiggyBank} value={dashBoardData?.commissions} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.CURRENCY} title={'pending.commissions'} icon={faPiggyBank} value={dashBoardData?.pendingCommissions} color={HeaderItemColor.GREEN} />
      <DashboardReportHeaderItem type={HeaderItemType.NUMBER} title={'cancellations'} icon={faArrowTrendDown} value={dashBoardData?.canceledBookings} color={HeaderItemColor.BLUE} />
    </div >
  );
}
