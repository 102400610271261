import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bookingManualSliceActions, getMyProfile, ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { ChangePassword, Reservations } from '@components';

import { Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faChartLine, faCog, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { AddPayment } from '../../my-profile/add-payment';
import { UpdateProfile } from '../../my-profile/update-profile';

import { DashboardItem, DashboardMenuItem } from '../dashboard-item';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { DashboardReport } from '../dashboard-report';
import { DashboardSite } from '../dashboard-site';

import './style.scss';
import { useIntl } from 'react-intl';
import { DashboardManualBooking } from '../dashboard-manual-booking';
import { NULL_VALUE } from '@constants';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getMyProfile: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

interface MenuItems {
  title: string;
  key: string;
  icon: IconProp;
  menu: DashboardMenuItem[];
}

function DashboardComponent(props: IProps) {
  const { loginStore, getMyProfile } = props;
  const { loading } = loginStore;

  const [option, setOption] = useState('11');

  const intl = useIntl();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      getMyProfile();
    }
  }, [loading])

  const ITEMS: MenuItems[] = [
    {
      title: 'My Reports',
      key: '1',
      icon: faChartLine,
      menu: [ { title: 'Report', key: '11', render: () => <DashboardReport /> } ]
    },
    {
      title: 'My Profile',
      key: '2',
      icon: faCog,
      menu: [
        {
          title: 'Profile Information',
          key: '12',
          render: () => <div className="my-profile-wrapper"><UpdateProfile /></div>
        },
        {
          title: 'Change Password',
          key: '22',
          render: () => <div className="my-profile-wrapper"><ChangePassword hideTitle horizontal /></div>
        },
        {
          title: 'Saved Credit cards',
          key: '32',
          render: () => <div className="my-profile-wrapper add-payment"><AddPayment /></div>
        }
      ]
    },
    {
      title: 'My Website',
      key: '3',
      icon: faGlobe,
      menu: [ { title: 'My Website', key: '13', render: () => <DashboardSite /> } ]
    },
    {
      title: 'My Reservations',
      key: '5',
      icon: faCalendarAlt,
      menu: [ { title: 'My Reservations', key: '15', render: () => <Reservations /> } ]
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.bookingupload' }),
      key: '6',
      icon: faChartLine,
      menu: [
        { title: 'Bookings - Manual Upload', key: '16', render: () => <DashboardManualBooking /> },
      ],
    },
  ];
  
  const handleTabChange = (tab: string): void => {
    setOption(`1${tab}`);

    if (tab === '6') {
      dispatch(bookingManualSliceActions.setPostManualBookingLoading(false));
      dispatch(bookingManualSliceActions.setPostManualBookingError(''));
      dispatch(bookingManualSliceActions.setPostManualBooking(NULL_VALUE));
    }
  };

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-wrapper__wrapper">
        {!loading && (
          <Tabs onChange={handleTabChange} defaultActiveKey={'1'}>
            {ITEMS.map(i => (
              <Tabs.TabPane
                tab={
                  <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={i.icon} /><label style={{ marginLeft: '5px' }}>{i.title}</label>
                  </span>}
                key={i.key}
              >
                <DashboardItem
                  selectedOption={option}
                  menu={i.menu}
                  setSelectedOption={setOption}
                />
              </Tabs.TabPane>))}
          </Tabs>)}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getMyProfile
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
