
import React from 'react';

import { isEmpty } from 'lodash';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ICarsState, ICondosState, IHotelsState, ILoginState } from '@share/store/slices';
import { GetWalletNumber, RootState, renderNumber } from '@share/utils';
import { Currency } from '../currency';

import { WalletPriceType, getWalletPrices } from '@utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
  carsStore: ICarsState;
  condosStore: ICondosState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  price: number;
  clientCashAppliedComplete?: number;
  maxClientCash?: number;

  currency: string;
  
  type: WalletPriceType;

  style?: any;

  displayOnlyRate?: boolean;

  isComplete?: boolean;
  isCondo?: boolean;
  isCars?: boolean;
  isOneLine?: boolean;
  isShort?: boolean;

  forcePublicText?: boolean;
  hideApplied?: boolean;
  greenText?: boolean;
}

class WalletMessageComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const {  price, currency, style, isOneLine, type, clientCashAppliedComplete, isComplete, isCondo, isCars, isShort, hideApplied, displayOnlyRate, greenText, forcePublicText, maxClientCash, hotelsStore, carsStore, condosStore, loginStore } = this.props;
    const { account, userWalletData } = loginStore;

    if (!account?.hasClientCash || (!account?.walletHasSliderOnSearch && !maxClientCash)) {
      return null;
    }
    
    const { selectedHotelSearchClientCash } = hotelsStore;
    const { selectedCondoSearchClientCash } = condosStore;
    const { selectedCarsSearchClientCash } = carsStore;

    const selectedSearchClientCash = isCondo ? selectedCondoSearchClientCash : isCars ? selectedCarsSearchClientCash : selectedHotelSearchClientCash;

    const prices = getWalletPrices(account, userWalletData, selectedSearchClientCash, price, maxClientCash, type);
    const convertionRate = prices?.convertionRate ? prices.convertionRate : 1;

    const walletNoDecimals = account?.walletNoDecimals;
    const clientCashAppliedCompleteCalculated = walletNoDecimals ? Math.floor(((clientCashAppliedComplete ? clientCashAppliedComplete : 0) / convertionRate) * 100) / 100 : (clientCashAppliedComplete ? clientCashAppliedComplete : 0) / convertionRate;
    const clientCashApplied = (isComplete && clientCashAppliedComplete) ? clientCashAppliedCompleteCalculated : prices?.clientCashApplied;
    const clientCashAmountApplied = (isComplete && clientCashAppliedComplete) ? clientCashAppliedComplete : prices?.clientCashAmountApplied;

    const textStyles: any = {};
    if (!isOneLine) {
      textStyles.justifyContent = 'flex-end';
    }

    const clientCashName = isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName;

    const maxClientCashFinal = maxClientCash ? account?.walletNoDecimals ? Math.floor(maxClientCash) : maxClientCash : 0;
    const maxClientCashAmount = maxClientCash ? maxClientCashFinal * convertionRate : 0;

    return (
      <div className="wallet-message-container" style={{ ...style }}>
        <div className="hotel-card-wrapper__price-public" style={{ marginTop: '10px', marginBottom: '7px' }}>
          {!isShort ? (
            <p className="hotel-card-wrapper__public-text rate">
              <FormattedMessage id={forcePublicText ? 'public' : 'wallet.client.cash.rate'} />
            </p>) : null}
          <p className="hotel-card-wrapper__public-text rate">
            <Currency currency={currency} />{renderNumber((Math.floor(price * 100) / 100), 2)}
          </p>
        </div>

        {!displayOnlyRate ? (
          <>
            {(maxClientCash && !account?.isCapitalVacations) ? (
              <>
                <div className="hotel-card-wrapper__price-public" style={{ ...textStyles, marginBottom: '10px' }}>
                  <p className={`hotel-card-wrapper__commission-text bigger ${greenText ? 'green' : ''}`}>
                    {(account?.walletHideAmount && type !== WalletPriceType.Search) ? (
                      <FormattedMessage id="wallet.client.cash.max_allowed_no_value" values={{ clientCashName }} />) :
                      !isShort ? (
                        <FormattedMessage id="wallet.client.cash.max_allowed" values={{ clientCash: GetWalletNumber(maxClientCashFinal, account), clientCashName }} />) :
                        `${GetWalletNumber(maxClientCashFinal, account)} ${clientCashName}`}
                  </p>
                  {(isOneLine && !account?.walletHideAmount) ? (
                    <p className={`hotel-card-wrapper__commission-text ${greenText ? 'green' : ''}`}>
                      <Currency currency={currency} />{renderNumber(maxClientCashAmount, 2)}
                    </p>) : null}
                  {(isOneLine && account?.walletHideAmount && type !== WalletPriceType.Search) ? (
                    <p className={`hotel-card-wrapper__commission-text ${greenText ? 'green' : ''}`}>
                      {GetWalletNumber(maxClientCashFinal, account)}
                    </p>) : null}
                </div>
                {(!isOneLine && !account?.walletHideAmount) ? (
                  <div className="hotel-card-wrapper__price-public" style={{ ...textStyles, marginBottom: '10px'}}>
                    <p className={`hotel-card-wrapper__commission-text ${greenText ? 'green' : ''}`}>
                      {`${GetWalletNumber(maxClientCashFinal, account)} ${clientCashName}`}
                    </p>
                  </div>) : null}
              </>) : null}

            {(!hideApplied && (type === WalletPriceType.Book || account?.walletHasSliderOnSearch || (type === WalletPriceType.Details && account?.walletWalletSavings))) ? (
              <>
                <div className="hotel-card-wrapper__price-public" style={textStyles}>
                  <p className={`hotel-card-wrapper__commission-text bigger ${greenText ? 'green' : ''}`}>
                    {(account?.walletHideAmount && type !== WalletPriceType.Search) ? (
                      <FormattedMessage id="wallet.client.cash.applied_no_value" values={{ clientCashName }} />) :
                      !isShort ? (
                        <FormattedMessage id="wallet.client.cash.applied" values={{ clientCash: GetWalletNumber(clientCashApplied, account), clientCashName }} />) :
                        `${GetWalletNumber(clientCashApplied, account)} ${clientCashName}`}
                  </p>
                  {(isOneLine && !account?.walletHideAmount) ? (
                    <p className={`hotel-card-wrapper__commission-text ${greenText ? 'green' : ''}`}>
                      <Currency currency={currency} />{renderNumber(clientCashAmountApplied, 2)}
                    </p>) : null}
                  {(isOneLine && account?.walletHideAmount && type !== WalletPriceType.Search) ? (
                    <p className={`hotel-card-wrapper__commission-text ${greenText ? 'green' : ''}`}>
                      {GetWalletNumber(clientCashApplied, account)}
                    </p>) : null}
                </div>
                {(!isOneLine && !account?.walletHideAmount) ? (
                  <div className="hotel-card-wrapper__price-public" style={textStyles}>
                    <p className={`hotel-card-wrapper__commission-text ${greenText ? 'green' : ''}`}>
                      <Currency currency={currency} />{renderNumber(clientCashAmountApplied, 2)}
                    </p>
                  </div>) : null}
              </>) : null}
          </>) : null}
      </div>
      );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    condosStore: state.condosStore,
    carsStore: state.carsStore,
    loginStore: state.loginStore
  };
};

export const WalletMessage = connect(mapStateToProps)(injectIntl(WalletMessageComponent));

