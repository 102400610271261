import React from 'react';
import ReactGA from 'react-ga4';

import { Popover, Tabs, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { IHotelDetails, IPackage, IRoomsContent, RefundableEnum } from '@common-types';
import { IAccount, IClientCash, IUserWallet } from '@share/common-types';
import { ModalCancellationPolicies, Currency, VacantionRentalPossiblestaysDisclaimer, WalletMessage } from '@components';
import { BlueButton } from '@share/components';
import { DATE_FORMAT_FOR_BOOKINGS, UNDEFINED_VALUE } from '@constants';
import { getBookRoutePath, renderNumber, formatDateCheckInOut, IsMinDate, insertSpacesAndPluralize, RootState, getToken, LoginType } from '@share/utils';
import {
  ManDarkSvg,
  BedSmallSvg,
  InfoSvg,
  ArrowBottomSvg,
  ArrowTopSvg,
  ImagesQuantitySvg,
  HomeClockSvg,
  BathSvg,
  PetsSvg,
  SmokingOffSvg,
  PetsOffSvg,
  SmokingVacSvg,
  SquareFootageSvg,
  KitchenSizeSvg,
  CoffeeMakerSvg
} from '@assets';
import NotImageImg from '@assets/images/not_image_room.png';
import Package from '@assets/images/package.png';
import { D_RESERVE_BUTTON } from '@share/constants';
import { IHotelsState, ILoginState, IMarginatorState, IMenuState, hotelsActions } from '@share/store/slices';
import { IRoomsSearchState, setSelectedHotelReviewClientCash, setSelectedHotelReviewClientCashStr } from '@store/slices';

import { RoomsSearchPossibleStays } from '../rooms-search-possible-stays';
import { HotelImagesSlider } from '../hotel-image-slider';

import './style.scss';
import { WalletPriceType, getWalletPrices } from '@utils';

interface IMapStateToProps {
  loginStore: ILoginState;
  roomsSearchStore: IRoomsSearchState;
  hotelsStore: IHotelsState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setSelectedHotelReviewClientCash: (clientCash: number) => void;
  setSelectedHotelReviewClientCashStr: (selectedClientCash: string) => void;
  setSelectedHotelSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  account: IAccount;
  groupedPackages: IPackage[];
  getRoomContent: (room: string) => IRoomsContent;
  nights: number;
  hotelId: number;
  isPreferred?: boolean;
  isVacationRentals?: boolean;
  isStaticDetail?: boolean;
  preferredRoomSavingPct?: number;
  marginator: IMarginatorState;
  hotelDetail: IHotelDetails;
}

interface IState {
  isAllAmenitiesShown: boolean;
  isSessionExpired: boolean;
  selectedPackageId: string;
  additionalHeight: number;
  showImageSlider: boolean;
  isModalCancellationPoliciesVisible: boolean;
  imageError: boolean;
  cancellationPoliciesText: string;
  refundabilityText: string;
  isChangedCancellationPolicy: boolean;
  nameHeight: number;
}

const zero = 0;
const one = 1;
const four = 4;
const timeoutShow = 5000;

class RoomComponent extends React.Component<IProps, IState> {
  private timeout: number = UNDEFINED_VALUE;
  state: IState;
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  roomRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);
    const currentPackage = props.groupedPackages[zero];

    this.state = {
      isAllAmenitiesShown: false,
      isSessionExpired: false,
      selectedPackageId: currentPackage.packageId,
      additionalHeight: 0,
      showImageSlider: false,
      imageError: false,
      isModalCancellationPoliciesVisible: false,
      cancellationPoliciesText: currentPackage.cancellationPolicyText as string,
      refundabilityText: currentPackage.refundabilityText ? currentPackage.refundabilityText : '',
      isChangedCancellationPolicy: false,
      nameHeight: 0,
    };
  }

  openImageSlider = () => {
    this.setState({ showImageSlider: true });
  };

  closeImageSlider = () => {
    this.setState({ showImageSlider: false });
  };

  toggleShowAll = (): void => {
    this.setState((prevState: IState) => ({ isAllAmenitiesShown: !prevState.isAllAmenitiesShown }));
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target as Node)) {
      this.setState({ isAllAmenitiesShown: false });
    }
  };

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);

    if (this.roomRef && this.roomRef.current) {
      const rooms = document.querySelectorAll('.rooms-search__room');

      if (rooms && rooms.length) {
        const { offsetHeight } = this.roomRef.current;
        let maxHeight = offsetHeight;

        rooms.forEach((item: any) => {
          if (item.offsetHeight > maxHeight) {
            maxHeight = item.offsetHeight;
          }
        });

        if (maxHeight > offsetHeight) {
          this.setState({ additionalHeight: maxHeight - offsetHeight });
        }
      }

      const names = document.querySelectorAll('.rooms-search__name-wrapper');

      if (names && names.length) {
        let maxHeight = 0;

        names.forEach((item: any) => {
          if (item.scrollHeight > maxHeight) {
            maxHeight = item.scrollHeight;
          }
        });

        this.setState({ nameHeight: maxHeight });
      }
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onRoomSelect = (e: RadioChangeEvent): void => {
    this.setState({ selectedPackageId: e.target.value, isChangedCancellationPolicy: true });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // @ts-ignore
    this.timeout = setTimeout(() => {
      this.setState({ isChangedCancellationPolicy: false });
    }, timeoutShow);
  };

  getReserveRoomUrl = (selectedPackageId: string): string => {
    const { hotelId, history, isVacationRentals, isStaticDetail } = this.props;
    return getBookRoutePath(hotelId, selectedPackageId, history, isVacationRentals, isStaticDetail);
  };

  handleReserve = (selectedPackageId: string, walletPrices: any) => {
    const { history, account } = this.props;

    const token = getToken(account);
    if (account?.type === LoginType.PrivateWithToken && !token) {
      this.setState({ isSessionExpired: true }); 
    } else {

      const { hotelsStore } = this.props;
      const { selectedHotelSearchClientCash } = hotelsStore;
    
      const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && walletPrices?.maxClientCashAmountAllow;

      const selectedPropertyClientCash = displayWalletSavings ? walletPrices?.maxClientCashPermited : selectedHotelSearchClientCash?.selectedPropertyClientCash;
  
      this.props.setSelectedHotelReviewClientCash(selectedPropertyClientCash);
      this.props.setSelectedHotelReviewClientCashStr(selectedPropertyClientCash ? selectedPropertyClientCash.toString() : '');
      this.props.setSelectedHotelSearchClientCash({ ...selectedHotelSearchClientCash, selectedPropertyReviewClientCash: selectedPropertyClientCash });

      history.push(this.getReserveRoomUrl(selectedPackageId));

      ReactGA.event({
        category: account.name,
        action: `${D_RESERVE_BUTTON}_${account.name.toUpperCase()}`,
        label: `User clicked reserve button on detail`,
        nonInteraction: false,
      });
    }
  }

  onCancelSlider = (_e: React.MouseEvent<HTMLElement, MouseEvent>, isReserveRoom?: boolean) => {
    this.closeImageSlider();

    if (isReserveRoom) {
      const { history } = this.props;
      const { selectedPackageId } = this.state;

      history.push(this.getReserveRoomUrl(selectedPackageId));
    }
  };

  showCancellationPolicies = (): void => {
    this.setState({
      isModalCancellationPoliciesVisible: true,
      cancellationPoliciesText: this.getPackageInfo()?.cancellationPolicyText as string,
      refundabilityText: this.getPackageInfo()?.refundabilityText as string,
    });
  };

  handleCancelModalCancellationPolicies = (): void => {
    this.setState({ isModalCancellationPoliciesVisible: false });
  };

  getPackageInfo = () => {
    return this.props.groupedPackages.find((item) => {
      return item.packageId === this.state.selectedPackageId;
    });
  };

  renderPreferredMessage = () => {
    if (this.props.isPreferred) {
      return (
        <div className="rooms-search__room-image-preferred"><FormattedMessage id="sort.most.booked" /></div>
      )
    }

    return null;
  }

  render(): React.ReactNode {
    const {
      isAllAmenitiesShown,
      selectedPackageId,
      showImageSlider,
      isModalCancellationPoliciesVisible,
      cancellationPoliciesText,
      refundabilityText,
      isChangedCancellationPolicy,
    } = this.state;
    const { groupedPackages, nights, isPreferred, roomsSearchStore, loginStore, hotelsStore, isVacationRentals, getRoomContent, marginator, hotelDetail, hotelId, account, preferredRoomSavingPct } = this.props;
    const currentPackage = groupedPackages[zero];
    const { packageId, rooms, refundability, price, pricePerNight, currency, possibleStays, maxWalletClientCash } = currentPackage;

    const disableExclusivePricingTagVacationRentals= account?.disableExclusivePricingTagVacationRentals;
    const disableSavingsTagVacationRentals= account?.disableSavingsTagVacationRentals;
    
    const { showCommission, showSupplier, supplier } = marginator;

    const unitPrice = possibleStays?.length ? possibleStays[zero].price : price;
    const maxWalletClientCashInt = maxWalletClientCash;

    const marginatorPrice = unitPrice / (1 - (marginator.value / 100));
    const marginatorpricePerNight = pricePerNight / (1 - (marginator.value / 100));
    const marginatorCommission = marginatorPrice - unitPrice;
    const { TabPane } = Tabs;

    const selectedPackage = groupedPackages.find(
      ({ packageId }) => packageId === selectedPackageId,
    );
    const selectedRoom = selectedPackage?.rooms[zero];
    const firstRoom = rooms[zero];
    const allRooms = groupedPackages.map(({ rooms, priceDifference, packageId }) => {
      return {
        packageId,
        priceDifference,
        room: rooms[zero],
      };
    });
    const isPackage = selectedPackage?.isPackage;
    const basisMinHeight = 70;

    if (!firstRoom) {
      return null;
    }

    const { bedType, roomName, adultsCount, kidsAges } = firstRoom;
    const adults = new Array(adultsCount).fill(one);
    const content = getRoomContent(selectedRoom?.targetRoomKey as string);
    const numberOfRooms = rooms.length;
    const values = ['Yes', 'No', 'Partial']

    const { error } = roomsSearchStore;
    const { userWalletData } = loginStore;
    const { selectedHotelSearchClientCash } = hotelsStore;

    const hotelPrices = getWalletPrices(account, userWalletData as IUserWallet, selectedHotelSearchClientCash, marginatorPrice, maxWalletClientCashInt, WalletPriceType.Details);

    const displayWalletSavings = account?.walletWalletSavings && (hotelPrices?.priceSavings > 0 || hotelPrices?.maxClientCashAmountAllow > 0) && !!hotelPrices?.maxClientCashAmountAllow;

    const marginatorPriceFinal = displayWalletSavings ? hotelPrices?.priceFinalMax : hotelPrices?.priceFinal;

    //mlg
    return (
      <>
        <div className={`rooms-search__room ${isPreferred ? 'preferred' : ''} vacation`} key={packageId} ref={this.roomRef}>
          <div className={`rooms-search__label-info ${isPreferred ? 'preferred' : ''} vacation`}>
            <p className="rooms-search__label-text"><FormattedMessage id="avg.rate" /></p>
            <p className="rooms-search__label-price"><Currency currency={currency} />{renderNumber(marginatorpricePerNight, 2)}</p>
            <p className="rooms-search__label-text"><FormattedMessage id="per.night" /></p>
          </div>

          <div className="row" style={{ marginRight: '-2px', marginBottom: '0', marginLeft: '-1px' }}>
            {
              isPreferred
                ? <div className={`rooms-search__room-image preferred vacation`}>{this.renderPreferredMessage()}</div>
                : null
            }
            <div className="col-md-4 col-sm-12 rooms-search__container">
              {(!this.state.imageError && content.images.length) ? (
                <>
                  <img
                    className="rooms-search__room-img"
                    src={content.images[zero]}
                    alt="room image"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NotImageImg;
                      this.setState({ imageError: true });
                    }}
                    onClick={this.openImageSlider}
                  />
                  <div className={`rooms-search__room-image-count ${isPreferred ? 'preferred-vacation' : 'vacation'}`} onClick={this.openImageSlider}>
                    <ImagesQuantitySvg />
                    <span className="rooms-search__images-quantity-text vacation">{content.images.length}+</span>
                  </div>
                </>
              ) : (
                <div>
                  <div className="rooms-search__room-image no-image-vacation">
                    <img src={NotImageImg} className="rooms-search__room-img" />
                  </div>
                  <div className="rooms-search__banner-not-img">
                    <span className="rooms-search__text-not-img">
                      <FormattedMessage id="room.not.image" />
                    </span>
                  </div>
                </div>
              )}

              {(!account?.isSaverRuleProfits && preferredRoomSavingPct && preferredRoomSavingPct > 0 && (!isVacationRentals || !disableSavingsTagVacationRentals) && (isPreferred || !account?.isSundance)) ? (
                <div className="hotel-card-wrapper__label-container room-vacations">
                  <div className="hotel-card-wrapper__label">
                    <p className="hotel-card-wrapper__label-title">
                      <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {preferredRoomSavingPct.toFixed(0)}%
                    </p>
                  </div>
                  <div className="hotel-card-wrapper__label-corner"></div>
                </div>) : null}
            </div>
            <div className="col-md-6 col-sm-12 rooms-search__center-wrapper">
              <div className={`row ${possibleStays?.length ? 'possible-stays' : ''}`}>
                {!isEmpty(roomName) ? (
                  <div className="col-12 align-self-start">
                    <div
                      className="rooms-search__name-wrapper"
                      style={{ height: '75px' }}
                    >
                      <Tooltip title={roomName}><div className="rooms-search__name secondary-font">{roomName}</div></Tooltip>
                    </div>
                  </div>) : null}

                <div className="col-12 align-self-start" style={{ marginTop: '10px' }}>
                  <div className="col">
                    <div className={`rooms-search__bed vacation pt-0 rental-info`}>
                      <>
                        {!!content.nbrOfBedrooms && <span style={{ marginRight: '1em' }}><BedSmallSvg /> {content.nbrOfBedrooms} {<FormattedMessage id="bedrooms" />}</span>}
                        {!!content.nbrOfBathrooms && <span style={{ marginRight: '1em' }}><BathSvg /> {content.nbrOfBathrooms} {<FormattedMessage id="bathrooms" />}</span>}
                        <span style={{ marginRight: '1em' }}>{content.petsAllowed ? <PetsSvg /> : <PetsOffSvg />} <FormattedMessage id="pets.allowed" />: <FormattedMessage id={content.petsAllowed ? "yes" : "no"} /></span>
                        <span>{content.nonSmoking ? <SmokingVacSvg /> : <SmokingOffSvg />} <FormattedMessage id="smoking.allowed" />: <FormattedMessage id={content.nonSmoking ? "yes" : "no"} /> </span>
                      </>
                    </div>
                  </div>
                </div>

                {(content.squareFootage || content.kitchenInfo?.length) ? (
                  <div className="col-12 align-self-start" style={{ marginTop: '10px' }}>
                    <div className="col">
                      <div className={`rooms-search__bed vacation pt-0`}>
                          {content.squareFootage && <span style={{ marginRight: '1em' }}><SquareFootageSvg size='18' /> {content.squareFootage} <FormattedMessage id="feet" /><sup>2</sup></span>}
                          {content.kitchenInfo?.length ?
                            content.kitchenInfo.map((x, i) => {
                              if (x.name === 'Coffee Maker' && values.some(val => x.details.includes(val))) {
                                return <span key={i} style={{ marginRight: '1em' }}><CoffeeMakerSvg size='18'/> <FormattedMessage id="coffee.maker" />: <FormattedMessage id={x.details[0].toLowerCase()} /></span>
                              }
                              if (x.name === 'Kitchen size' && values.some(val => x.details.includes(val))) {
                                return <span key={i} style={{ marginRight: '1em' }}><KitchenSizeSvg size='22' /> <FormattedMessage id="kitchen.size" />: <FormattedMessage id={x.details[0].toLowerCase()} /></span>
                              }
                            }) : null}
                      </div>
                    </div>
                  </div>) : null}

                <div className="col-12 align-self-start" style={{ marginTop: '10px' }}>
                  <div className="rooms-search__name-wrapper">
                    <div className="rooms-search__guests">
                      {adults.map((_value, i) => {
                        return (
                          <span className="rooms-search__icon-wrapper" key={i}>
                            <ManDarkSvg />
                          </span>
                        );
                      })}
                      {kidsAges?.length ? kidsAges?.map((_value, i) => {
                        return (
                          <span className="rooms-search__icon-wrapper kids" key={i}>
                            <ManDarkSvg />
                          </span>
                        );
                      }) : null}
                    </div>
                  </div>
                </div>

                {(bedType || (hotelDetail.whenBuild && !IsMinDate(hotelDetail.whenBuild))) ? (
                  <div className="col-12 align-self-start">
                    <div className="col">
                      <div className={`rooms-search__bed vacation`}>
                        {bedType && <span style={{ marginRight: '1em' }}><BedSmallSvg /> {insertSpacesAndPluralize(bedType)}</span>}
                        {(hotelDetail.whenBuild && !IsMinDate(hotelDetail.whenBuild)) && <span><HomeClockSvg /> <FormattedMessage id="year.built" />: {formatDateCheckInOut(hotelDetail.whenBuild.toString(), DATE_FORMAT_FOR_BOOKINGS)}</span>}
                      </div>
                    </div>
                  </div>) : null }

                <div className="col-12 align-self-start">
                  <div className="rooms-search__amenities">
                    {content.amenities.length > 0 ? (
                      <div ref={this.wrapperRef}>
                        <div>
                          {content.amenities.slice(zero, four).join(', ')}...
                          {content.amenities.length > four &&
                            <span className="rooms-search__amenities-more pl-1" onClick={this.toggleShowAll}>
                              <FormattedMessage id="more.details" />{' '}
                              {isAllAmenitiesShown ? <ArrowTopSvg /> : <ArrowBottomSvg />}
                            </span>}
                        </div>
                        {isAllAmenitiesShown && (
                          <div className="rooms-search__amenities-all">
                            {content.amenities.map((amenity) => {
                              return <div key={amenity}>{amenity}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      <span className="rooms-search__amenities-no-data">
                        <FormattedMessage id="no.info.amenities" />
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 align-self-end">
                  <div className="rooms-search__amenities pt-0">
                    <Tabs tabPosition={'bottom'} style={{ fontSize: '13px' }}>
                      {content.policy && <TabPane tab={<FormattedMessage id="policy" />} key="1">{content.policy}</TabPane>}
                      {content.petPolicy && <TabPane tab={<FormattedMessage id="pet.policy" />} key="2">{content.petPolicy}</TabPane>}
                      {content.extraInfo && <TabPane tab={<FormattedMessage id="extra.info" />} key="3">{content.extraInfo}</TabPane>}
                    </Tabs>
                  </div>
                </div>

                {(possibleStays?.length) ? (<div className="col-12 align-self-end">
                  <div className="rooms-search__amenities pt-0" style={{ marginBottom: '15px' }}>
                    <VacantionRentalPossiblestaysDisclaimer account={account} possibleStays={possibleStays} displayMoreOptions={false} displayFullDateFormat>
                    </VacantionRentalPossiblestaysDisclaimer>
                  </div>
                </div>) : null}

                {!account?.isMLM ? (
                  <div className="col-6 align-self-start">
                    <WalletMessage
                      price={marginatorPrice}
                      maxClientCash={maxWalletClientCashInt}
                      currency={currency}
                      type={WalletPriceType.Details}
                      isOneLine
                      hideApplied={displayWalletSavings}
                      greenText={displayWalletSavings}
                      style={{ paddingLeft: '15px', paddingRight: '15px' }}
                    />
                  </div>) : null}
              </div>
            </div>

            <div className="col-md-2 col-sm-12" style={{ alignSelf: 'end' }}>
              {refundability !== RefundableEnum.Unknown ? (
                <div className="col-md-12 rooms-search__refundable vacation">
                  {refundability === RefundableEnum.Refundable && (
                    <div className="rooms-search__refundable-ref" onClick={this.showCancellationPolicies}>
                      <Popover
                        overlayClassName="overlay-popover"
                        content={<FormattedMessage id="changed.cancellation.policy" />}
                        visible={isChangedCancellationPolicy}
                        placement="bottomLeft"
                      >
                        <FormattedMessage id="refundable" />
                        <InfoSvg />
                      </Popover>

                    </div>
                  )}

                  {refundability === RefundableEnum.Nonrefundable && (
                    <div
                      className="rooms-search__refundable-nonref vacation"
                      onClick={this.showCancellationPolicies}
                    >
                      <Popover
                        overlayClassName="overlay-popover"
                        content={<FormattedMessage id="changed.cancellation.policy" />}
                        visible={isChangedCancellationPolicy}
                        placement="bottomLeft"
                      >
                        <FormattedMessage id="non.refundable" />
                        <InfoSvg />
                      </Popover>
                    </div>
                  )}

                  {(isPackage && (!isVacationRentals || !disableExclusivePricingTagVacationRentals)) && (
                    <div className="package-container vacation">
                      <div className="package-content">
                        <img src={Package} style={{ width: 14, marginRight: 5 }} />
                        <FormattedMessage id="result.package" />
                      </div>
                    </div>
                  )}

                </div>) : null}

              {allRooms && allRooms.length ? (
                <div className="col-md-12 rooms-search__basis-wrapper text-end" style={{ minHeight: `${basisMinHeight}px` }}>
                  {allRooms.map(({ room }, index) => {
                    if (isEmpty(room.roomBasis)) {
                      return <FormattedMessage key={index} id="accommodation.only" />
                    }
                    return <div key={index} className="rooms-search__basis">{room.roomBasis}</div>;
                  })}
                </div>) : (
                <div style={{ minHeight: `${basisMinHeight}px` }} />)}

              {selectedPackage?.rooms && selectedPackage?.rooms[0]?.specialDeals.length > 0 &&
                <ul>
                  {selectedPackage?.rooms[0].specialDeals.map((value, i) => {
                    return (<li key={i}>{value}</li>)
                  })}
                </ul>}

              <div className="col-md-12 rooms-search__bottom d-flex justify-content-end">
                <div className="rooms-search__price">
                  <div className="hotel-card-wrapper__price-total">
                    <p className="hotel-card-wrapper__price-total-text d-flex justify-content-end">
                      <span style={{fontSize: '13px', marginRight: '4px'}}><Currency currency={currency} /></span>{renderNumber(marginatorPriceFinal, 2)}
                    </p>
                    <div className="hotel-card-wrapper__for-info">
                      <p className="hotel-card-wrapper__for-info-text">
                        <FormattedMessage id="for" />{' '}
                        <FormattedMessage id="nights" values={{ count: nights }} />{' '}
                      </p>
                    </div>
                    <div className="hotel-card-wrapper__for-info">
                      <span className="rooms-search__price-includes-taxes">
                        <FormattedMessage id={account?.breakdownTaxes ? 'abbreviated.price.without.taxes.fees' : 'abbreviated.price.includes.taxes.fees'} />
                      </span>
                    </div>
                    {showCommission && (
                      <div className="hotel-card-wrapper__price-public">
                        <p className="hotel-card-wrapper__commission-text">
                          <FormattedMessage id="commission.price" />
                        </p>
                        <p className="hotel-card-wrapper__commission-text">
                          <Currency currency={currency} />{renderNumber(marginatorCommission, 2)}
                        </p>
                      </div>
                    )}
                    {showSupplier && (
                      <div className="hotel-card-wrapper__price-public">
                        <p className="hotel-card-wrapper__supplier-text"><FormattedMessage id="supplier" />:</p>
                        <p className="hotel-card-wrapper__supplier-text">{supplier}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={`rooms-search__bottom rooms-search__action ${numberOfRooms !== one ? 'more-rooms' : ''}`}>
                <BlueButton disabled={!isEmpty(error)} onClick={() => this.handleReserve(selectedPackageId, hotelPrices)} className="btn btn-block w-100">
                  {numberOfRooms === one ? (
                    <FormattedMessage id="reserve" />
                  ) : (
                    <FormattedMessage id="reserve.count.rooms" values={{ count: numberOfRooms }} />
                  )}
                </BlueButton>
              </div>
              <HotelImagesSlider
                onCancel={this.onCancelSlider}
                visible={showImageSlider}
                images={content.images.map((img: string) => {
                  return {
                    description: 'room-image',
                    url: img,
                    height: zero,
                    width: zero,
                  };
                })}
                hotelName={roomName}
                startIndex={zero}
              />
              <ModalCancellationPolicies
                cancellationPoliciesText={cancellationPoliciesText}
                refundabilityText={refundabilityText}
                visible={isModalCancellationPoliciesVisible}
                onCancel={this.handleCancelModalCancellationPolicies}
              />
            </div>
          </div>
        </div>
        
        <RoomsSearchPossibleStays
          hotelId={hotelId}
          possibleStays={possibleStays}
          groupedPackages={currentPackage}
          isVacationRentals={isVacationRentals}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    roomsSearchStore: state.roomsSearchStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,
  setSelectedHotelSearchClientCash: hotelsActions.setSelectedHotelSearchClientCash,
};

const RoomVacationRouter = withRouter(RoomComponent);

export const RoomVacation = connect(mapStateToProps, mapDispatchToProps)(RoomVacationRouter);
