import React from 'react';

import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { ILoginState } from '@share/store/slices';
import { FunctionalRootState } from '@share/utils';

import './style.scss';

interface Message {
  message: string;
  values: any;
}

interface IProps {
  message: string | Message;
  messageAux?: string | Message;
  title: string;
  yesMessage: string;
  noMessage: string;
  visible: boolean;
  disabled?: boolean;

  disableMessageLocalization?: boolean;

  auxMessageColor?: string;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  onConfirm: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export function ModalConfirmation(props: IProps) {

  const {
    title,
    message,
    messageAux,
    auxMessageColor,
    yesMessage,
    noMessage,
    visible,
    disabled,
    disableMessageLocalization,
    onCancel,
    onConfirm,
  } = props;

  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);
    
  let values = {};
  if (typeof message !== 'string') {
    values = { ...message.values };
  }

  values = { 
    ...values,
    BR: () => <br />,
    title: (message: string) => (<label style={{ width: '100px' }}>{message}</label>),
    strong: (message: string) => (<strong>{message}</strong>)
  };

  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;

  const auxStyles: any = { color: auxMessageColor ? auxMessageColor : '#000000' };

  return (
    <Modal
      className={`modal-cancellation-policies ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
      open={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="modal-cancellation-policies__wrapper"
    >
      <div>
        <h4 className="modal-cancellation-policies__title secondary-font">
          <FormattedMessage id={title} />
        </h4>
        <div className="modal-cancellation-policies__refundability">
          <p className="modal-cancellation-policies__refundability-description">
            {!disableMessageLocalization ? (
              <FormattedMessage id={typeof message === 'string' ? message : message.message}  values={values} />) : (
              <>{message}</>)}
          </p>
          {messageAux && (
            <p className="modal-cancellation-policies__refundability-description" style={{ ...auxStyles, marginTop: '6px' }} >
              <FormattedMessage id={typeof messageAux === 'string' ? messageAux : messageAux.message}  values={values} />
            </p>)}

          <div className="modal-cancellation-policies__buttons">
            <WhiteButton onClick={onCancel} disabled={disabled} >
              <FormattedMessage id={noMessage} />
            </WhiteButton>
            <BlueButton onClick={onConfirm} disabled={disabled} >
              <FormattedMessage id={yesMessage} />
            </BlueButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
