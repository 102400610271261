import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@share/utils';
import { BookingManualAPI } from '@share/api';
import { NULL_VALUE } from '@constants';
import { TablePaginationConfig } from 'antd';
import { IBookingManualSort, IFiltersManualBooking } from '../../../components/components/dashboard/dashboard-manual-booking';

export interface IBookingRequest {
  items: [];
  totalResults: number;
  totalPages: number;
}
export interface IPostBookingManual {
 id: number,
 success: boolean
}
export interface IBookingManualState {
  bookingManual: IBookingRequest | null;
  loadingBookingManual: boolean;
  bookingManualError: string;
  postBookingManual: IPostBookingManual | null;
  postBookingManualLoading: boolean;
  postBookingManualError: string;
}

const initialState: IBookingManualState = {
  bookingManual: {
    items: [],
    totalResults: 0,
    totalPages: 0,
  },
  loadingBookingManual: false,
  bookingManualError: '',
  postBookingManual: NULL_VALUE,
  postBookingManualLoading: false,
  postBookingManualError: ''
};

const bookingManualSlice = createSlice({
  name: 'bookingManual',
  initialState,
  reducers: {
    setManualBookingLoading: (state: IBookingManualState, { payload }: PayloadAction<boolean>) => {
      state.loadingBookingManual = payload;
    },
    setManualBookingError: (state: IBookingManualState, { payload }: PayloadAction<string>) => {
      state.bookingManualError = payload;
    },
    setManualBooking: (state: IBookingManualState, { payload }: PayloadAction<IBookingRequest>) => {
      state.bookingManual = payload;
    },
    setPostManualBookingLoading: (state: IBookingManualState, { payload }: PayloadAction<boolean>) => {
      state.postBookingManualLoading = payload;
    },
    setPostManualBookingError: (state: IBookingManualState, { payload }: PayloadAction<string>) => {
      state.postBookingManualError = payload;
    },
    setPostManualBooking: (state: IBookingManualState, { payload }: PayloadAction<IPostBookingManual>) => {
      state.postBookingManual = payload;
    },
  },
});

export const bookingManualSliceActions = bookingManualSlice.actions;

export const bookingManualSliceReducer = bookingManualSlice.reducer;

export const getBookingManual = (
  pagination: TablePaginationConfig,
  agentID: number,
  sort: IBookingManualSort,
  filters?: IFiltersManualBooking,
): AppThunk => {
  return async (dispatch) => {
    dispatch(bookingManualSliceActions.setManualBookingLoading(true));
    dispatch(
      bookingManualSliceActions.setManualBooking(NULL_VALUE),
    );

    try {
      const { data } = await BookingManualAPI.get(pagination, agentID, sort, filters);

      if (data?.isNotEmpty) {
        dispatch(
          bookingManualSliceActions.setManualBooking({
            items: data?.items || [],
            totalResults: data?.totalResults,
            totalPages: data?.totalPages,
          }),
        );
      } else if (data?.isEmpty) {
        dispatch(bookingManualSliceActions.setManualBookingError('No data available'));
      } else {
        dispatch(
          bookingManualSliceActions.setManualBookingError(
            'An error has occurred, please try again later.',
          ),
        );
      }
    } catch (error: any) {
      console.error(error);

      let errorMessage = 'An unknown error occurred.';
      if (error.response) {
        const { status, data } = error.response;
        errorMessage = `Error ${status}: ${data?.message || 'Unknown server error'}`;
      } else if (error.request) {
        errorMessage = 'No response received from the server. Please check your network.';
      } else {
        errorMessage = `Request setup failed: ${error.message}`;
      }

      dispatch(bookingManualSliceActions.setManualBookingError(errorMessage));
    } finally {
      dispatch(bookingManualSliceActions.setManualBookingLoading(false));
    }
  };
};

export const postBookingManual = (requestBody: any, onSuccess?: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(bookingManualSliceActions.setPostManualBookingLoading(true));
    dispatch(bookingManualSliceActions.setPostManualBooking(NULL_VALUE));

    try {
      const response = await BookingManualAPI.post(requestBody);
      const { data } = response;

      if (data.success) {
        dispatch(bookingManualSliceActions.setPostManualBooking(data));

        if (onSuccess) {
          onSuccess();
        }
      } else {
        dispatch(
          bookingManualSliceActions.setPostManualBookingError(`${data.status} ${data.message}`),
        );
      }
    } catch (error: any) {
      console.error(error);

      let errorMessage = 'An unknown error occurred.';
      if (error.response) {
        const { status, data } = error.response;
        errorMessage = `Error ${status}: ${data?.message || 'Unknown server error'}`;
      } else if (error.request) {
        errorMessage = 'No response received from the server. Please check your network.';
      } else {
        errorMessage = `Request setup failed: ${error.message}`;
      }

      dispatch(bookingManualSliceActions.setPostManualBookingError(errorMessage));
    } finally {
      dispatch(bookingManualSliceActions.setPostManualBookingLoading(false));
    }
  };
};


