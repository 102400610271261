import React, { Component } from 'react';
import moment from 'moment';

import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { Modal } from 'antd';
import { get } from 'lodash';
import { v4 } from 'uuid';

import { GetDisableNewTabOpen, RootState, UrlUtils } from '@share/utils';
import { ICondoFiltersState, ICondoFlexibleDatePickerState, IDatesState, IFiltersState, ILocationsState, ILoginState, IMenuState, IRoomsState, condoFiltersInitialState, filtersInitialState } from '@share/store/slices';
import { CONDO_DATES_FLEXIBLE_LABEL, CONDO_DATES_LABEL, CONDO_FILTERS_LABEL, CONDO_GUESTS_LABEL, CONDO_IS_FLEXIBLE_LABEL, CONDO_LOCATIONS_LABEL, CONDO_LOCATIONS_OBJECT_LABEL, CONDO_SESSION_KEY, DATES_LABEL, FILTERS_LABEL, LOCATION_LABEL, LOCATION_OBJECT_LABEL, ROOMS_LABEL, Routes } from '@share/constants';
import { DateSearchTypeEnum, ISessionKey } from '@share/common-types';
import { SearchWrapper } from '@share/components';
import { CondoSearch } from '@share/components';
import { ICondoGuestsState, ICondoStrictDatesState, ICondosLocationPickerState } from '@share/store/slices';

import './style.scss';


const TREE_STARS = 3;
const FOUR_STARS = 4;
const FIVE_STARS = 5;

const TREE_STARS_BUDGET = 75;
const FOUR_STARS_BUDGET = 250;
const FIVE_STARS_BUDGET = 500;

const CONDO_I_BUDGET = 75;
const CONDO_0_BUDGET = 250;
const CONDO_1_BUDGET = 500;
const CONDO_2_BUDGET = 1000;
const CONDO_3_BUDGET = 2500;

const HOTEL_BUDGET_1 = { from: null, to: TREE_STARS_BUDGET } as BudgetRange;
const HOTEL_BUDGET_2 = { from: TREE_STARS_BUDGET, to: FOUR_STARS_BUDGET } as BudgetRange;
const HOTEL_BUDGET_3 = { from: FOUR_STARS_BUDGET, to: FIVE_STARS_BUDGET } as BudgetRange;

const CONDO_BUDGET_0 = { from: CONDO_I_BUDGET, to: CONDO_0_BUDGET } as BudgetRange;
const CONDO_BUDGET_1 = { from: CONDO_0_BUDGET, to: CONDO_1_BUDGET } as BudgetRange;
const CONDO_BUDGET_2 = { from: CONDO_1_BUDGET, to: CONDO_2_BUDGET } as BudgetRange;
const CONDO_BUDGET_3 = { from: CONDO_2_BUDGET, to: CONDO_3_BUDGET } as BudgetRange;

export enum SearchType {
  Condos1 = 'Condos1',
  Condos2 = 'Condos2',
  Condos3 = 'Condos3',
  Hotels1 = 'Hotels1',
  Hotels2 = 'Hotels2',
  Hotels3 = 'Hotels3',
}

const CONDO_TYPES = [SearchType.Condos1, SearchType.Condos2, SearchType.Condos3];

const TypeObject = {
  Hotels1: {
    star: TREE_STARS,
    budgets: [ HOTEL_BUDGET_1 ]
  },
  Hotels2: {
    star: FOUR_STARS,
    budgets: [ HOTEL_BUDGET_2 ]
  },
  Hotels3: {
    star: FIVE_STARS,
    budgets: [ HOTEL_BUDGET_3 ]
  },
  Condos1: {
    budgets: [ CONDO_BUDGET_0, CONDO_BUDGET_1 ]
  },
  Condos2: {
    budgets: [ CONDO_BUDGET_2 ]
  },
  Condos3: {
    budgets: [ CONDO_BUDGET_3 ]
  },
}

interface BudgetRange {
  from: number | null | undefined;
  to: number;
}

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;

  datesStore: IDatesState;
  roomsStore: IRoomsState;
  locationsStore: ILocationsState;

  condosLocationPickerStore: ICondosLocationPickerState;
  condoGuestsStore: ICondoGuestsState;
  condoStrictDatesStore: ICondoStrictDatesState;
  condoFlexibleDatePickerStore: ICondoFlexibleDatePickerState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  visible: boolean;
  type: SearchType;

  onCancel: () => void;
}

class ModalExperiencesSearchComponent extends Component<IProps> {

  handleHotelSearch = () => {
    const { type, loginStore, datesStore, locationsStore, roomsStore, menuStore } = this.props;
    const { startDateSelected, endDateSelected } = datesStore;
    const { selectedLocation, selectedLocationLabel } = locationsStore;
    const { roomsSelected } = roomsStore;
    const { account } = loginStore;
    const { items } = menuStore;

    const star: number | undefined = get(TypeObject, `${type}.star`);
    const budgets: BudgetRange[] = get(TypeObject, `${type}.budgets`);
    const filters = { ...filtersInitialState } as IFiltersState;

    if (star) {
      filters.starRatings = [star];
    }

    if (budgets) {
      filters.budget = [...budgets.map(budget => `${budget.from}-${budget.to}`)];
    }
    
    const filtersParams = UrlUtils.getUrl(FILTERS_LABEL, filters);
    const guestParams = UrlUtils.getUrl(ROOMS_LABEL, roomsSelected.map(r => ({ adultsCount: r.adultsCount, kids: r.kids?.length ? r.kids.map(k => k.age) : []})));
    const locationParams = `${LOCATION_OBJECT_LABEL}=${Base64.encode(JSON.stringify(selectedLocation))}`;
    const locationLabelParams = UrlUtils.getUrl(LOCATION_LABEL, selectedLocationLabel);
    const datesParams = UrlUtils.getUrl(DATES_LABEL, { startDate: startDateSelected, endDate: endDateSelected });

    const disableNewTabOpen = GetDisableNewTabOpen(account, items);

    const path = import.meta.env.VITE_WIDGET_REDIRECT;
    window.open(`${path}/${account?.name}${Routes.Search}?${filtersParams}&${guestParams}&${locationParams}&${locationLabelParams}&${datesParams}`, disableNewTabOpen ? '_self' : '_blank');

    this.props.onCancel();
  }
  
  handleCondoSearch = () => {
    const { type, loginStore, condoGuestsStore, condosLocationPickerStore, condoStrictDatesStore, condoFlexibleDatePickerStore, menuStore } = this.props;
    const { account } = loginStore;
    const { stayFor, selectedMonths } = condoFlexibleDatePickerStore;
    const { searchType, startDate, endDate } = condoStrictDatesStore;
    const { selectedLocation, selectedLocationLabel } = condosLocationPickerStore;
    const { adultsCount, kidsCount, bedroomsCount, includeStudio } = condoGuestsStore;
    const { items } = menuStore;

    const budgets: BudgetRange[] = get(TypeObject, `${type}.budgets`);
    const filters = { ...condoFiltersInitialState } as ICondoFiltersState;

    if (budgets) {
      filters.budgetRanges = [...budgets.map(budget => `${budget.from}-${budget.to}`)];
    }

    const isFlexible = searchType === DateSearchTypeEnum.Flexible;
    const filtersParams = UrlUtils.getUrl(CONDO_FILTERS_LABEL, filters);
    const guestParams = UrlUtils.getUrl(CONDO_GUESTS_LABEL, { adultsCount, kids: kidsCount, bedroomsCount, includeStudio });
    const locationParams = `${CONDO_LOCATIONS_OBJECT_LABEL}=${Base64.encode(JSON.stringify(selectedLocation))}`;
    const locationLabelParams = UrlUtils.getUrl(CONDO_LOCATIONS_LABEL, selectedLocationLabel);
    const isFlexibleParams = UrlUtils.getUrl(CONDO_IS_FLEXIBLE_LABEL, isFlexible ? '0' : '1');
    const sessionParams = UrlUtils.getUrl(CONDO_SESSION_KEY, {
      value: v4(),
      isInvalid: true,
      expireDate: moment().format('yyyy-MM-DDTHH:mm:ss.SSSZ')
    } as ISessionKey);
    
    let datesParams;
    if (isFlexible) {
      datesParams = UrlUtils.getUrl(CONDO_DATES_FLEXIBLE_LABEL, { selectedMonths, stayFor });
    } else {
      datesParams = UrlUtils.getUrl(CONDO_DATES_LABEL, { startDate, endDate });
    }

    const disableNewTabOpen = GetDisableNewTabOpen(account, items);

    const path = import.meta.env.VITE_WIDGET_REDIRECT;
    window.open(`${path}/${account?.name}${Routes.CondoSearch}?${filtersParams}&${guestParams}&${locationParams}&${isFlexibleParams}&${locationLabelParams}&${datesParams}&${sessionParams}`, disableNewTabOpen ? '_self' : '_blank');

    this.props.onCancel();
  }
  
  handleSearch = () => {
    const { type } = this.props;
    const isCondo = CONDO_TYPES.includes(type);
    if (isCondo) {
      this.handleCondoSearch();
    } else {
      this.handleHotelSearch();
    }
  }

  render(): React.ReactNode {
    const { visible, type, onCancel } = this.props;

    const isCondo = CONDO_TYPES.includes(type);
    
    return (
      <Modal
        className="modal-experiences-search"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-experiences-search__wrapper"
      >
        {isCondo ? 
          <CondoSearch
            isWidget={false}
            isExperiences={true}
            forceVertical={true}
            onExperience={this.handleCondoSearch}
          /> : 
          <SearchWrapper
            isWidget={false}
            isExperiences={true}
            forceVertical={true}
            onExperience={this.handleHotelSearch}
          />}
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,

    datesStore: state.datesStore,
    roomsStore: state.roomsStore,
    locationsStore: state.locationsStore,
    condosLocationPickerStore: state.condosLocationPickerStore,
    condoStrictDatesStore: state.condoStrictDatesStore,
    condoFlexibleDatePickerStore: state.condoFlexibleDatePickerStore,
    condoGuestsStore: state.condoGuestsStore
  };
};

export const ModalExperiencesSearch = connect(mapStateToProps)(injectIntl(withRouter(ModalExperiencesSearchComponent)));