import React from 'react';

import { Modal, Spin, Button, Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { BlueButton } from '@share/components';
import { DownLoadShareType, HandleDownload, HandleShare } from '@share/api';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import { ModalConfirmation } from '../modal-confirmation';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps {
  visible: boolean;
  isShare: boolean;

  type: DownLoadShareType;

  convertData: (members: any, comment: string) => any;
  onCancel: () => void;
  onLoading: (loading: boolean) => void;
}

interface IState {
  loading: boolean;
  showConfirmModal: boolean;
  comment: string;
  errorMessage: string | undefined;
  members: string[];
}

class ModalShareComponent extends React.Component<IProps, IState> {

  state: IState = { loading: false, showConfirmModal: false, comment: '', members: [], errorMessage: undefined };

  onChange = (e: any) => {
    this.setState({ comment: e.target.value });
  }

  handleDownload = () => {
    const loading = this.state.loading;
    this.setState({ loading: true }, () => this.props.onLoading(true));
    HandleDownload(
      this.props.type,
      loading,
      this.props.convertData(this.state.members, this.state.comment),
      this.props.intl,
      () => this.setState({ loading: false }, () => {
        this.props.onLoading(false);
        this.props.onCancel();
      }),
      () => this.setState({ comment: '', members: [], errorMessage: undefined }));
  }

  handleShare = (skip: boolean) => {
    const loading = this.state.loading;
    this.setState({ loading: true }, () => this.props.onLoading(true));
    HandleShare(
      this.props.type,
      loading,
      {
        ...this.props.convertData(this.state.members, this.state.comment),
        continue: skip,
      },
      this.props.intl,
      () => this.setState({ loading: false }, () => {
        this.props.onLoading(false);
        this.props.onCancel();
      }),
      () => this.setState({ comment: '', members: [], errorMessage: undefined }),
      (message: string | undefined) => this.setState({ showConfirmModal: true }),
    );
  }

  onFinish = (values: any) => {
    this.setState({ members: values.names });
    this.handleShare(false);
  };

  onConfirm = () => {
    this.setState({ showConfirmModal: false });
    this.handleShare(true);
  }

  onCancel = () => {
    this.setState({ showConfirmModal: false, comment: '', members: [], loading: false, errorMessage: undefined }, () => {
      this.props.onLoading(false);
      this.props.onCancel();
    });
  }

  render(): React.ReactNode {
    const { TextArea } = Input;
    const { visible, isShare, loginStore } = this.props;
    const { comment, loading, showConfirmModal, errorMessage } = this.state;
    const { account } = loginStore;

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;

    return (
      <Modal
        className={`modal-share ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        centered
        footer={null}
        onCancel={() => this.setState({ comment: '', errorMessage: undefined }, () => this.props.onCancel())}
        wrapClassName="modal-share__wrapper"
        width={500}
      >
        <div>
          {isShare ?
            <>
              <h4 className="modal-share__title secondary-font">
                Enter MemberID or Member Email
              </h4>
              <div className="modal-share__hotels">
                <Form name="dynamic_form_item" style={{ width: '100%' }} {...formItemLayoutWithOutLabel} onFinish={this.onFinish}>
                  <Form.List
                    name="names"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(new Error('At least 1 recipient'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field) => (
                          <Form.Item
                            {...(formItemLayoutWithOutLabel)}
                            required={false}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input MemberID/Member Email or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <Input placeholder='Please input MemberID or Member Email' style={{ width: '90%' }} />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '90%' }}
                            icon={<PlusOutlined />}
                          >
                            Add another MemberID or Member Email
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <h4 className="modal-share__title secondary-font">
                    <FormattedMessage id="results.hotel.card.compare.modal.comment.title" />
                  </h4>
                  <div className="modal-share__hotels">
                    <TextArea rows={4} value={comment || ''} onChange={this.onChange} />
                  </div>

                  <div className="modal-share__buttons">
                    <BlueButton disabled={loading} htmlType="submit">
                      {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.share" />}
                    </BlueButton>
                  </div>
                </Form>
              </div>
            </>
            : null
          }

          {!isShare ?
            <>
              <h4 className="modal-share__title secondary-font">
                <FormattedMessage id="results.hotel.card.compare.modal.comment.title" />
              </h4>
              <div className="modal-share__hotels">
                <TextArea rows={4} value={comment || ''} onChange={this.onChange} />
              </div>

              <div className="modal-share__buttons">
                <BlueButton disabled={loading} onClick={this.handleDownload}>
                  {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
                </BlueButton>
              </div>
            </>: null}
        </div>

        <ModalConfirmation
          title="share.avail.title"
          message={!!errorMessage ? errorMessage : 'share.avail.message'}
          messageAux="share.avail.message.aux"
          auxMessageColor="red"
          yesMessage="continue"
          noMessage="cancel.button"
          disableMessageLocalization={!!errorMessage}
          visible={showConfirmModal}
        
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
        
        />
      </Modal>)
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalShare = connect(mapStateToProps)(injectIntl(withRouter(ModalShareComponent)));