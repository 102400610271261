
import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { getDashboarReports } from '@store/slices';

import { DashboardReportHeader } from './dashboard-report-header';
import { DashboardReportTopDestinations } from './dashboard-report-top-destinations';
import { DashboardReportTopMembers } from './dashboard-report-top-members';
import { DashboardReportRoomsBooked } from './dashboard-report-rooms-booked';
import { DashboardReportBookingsTotalBar } from './dashboard-report-bookings-total';
import { DashboardReportBookingsTotalPie } from './dashboard-report-bookings-total-pie';
import { DashboardReportFilters } from './dashboard-report-filters';

import './style.scss';

export const MONTHLY = 'MONTHLY';
export const DAILY = 'DAILY';

export function DashboardReport() {
  const [period, setPeriod] = useState(MONTHLY);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboarReports());
  }, []);

  return (
    <div className="dasshboard-reports-wrapper">
      <div className="dasshboard-reports-wrapper__title">
        <span className="title-description"><FontAwesomeIcon icon={faChartLine} /><h1><FormattedMessage id="trends" /></h1></span>
        <DashboardReportFilters />
      </div>

      <DashboardReportHeader />

      <div className="dasshboard-reports-wrapper__row">
        <div className="dasshboard-reports-wrapper__row-left">
          <DashboardReportBookingsTotalBar period={period} onPeriodChange={setPeriod} />
        </div>
        <div className="dasshboard-reports-wrapper__row-right">
          <DashboardReportBookingsTotalPie period={period} />
        </div>
      </div>

      <DashboardReportRoomsBooked />

      <div className="dasshboard-reports-wrapper__row">
        <div className="dasshboard-reports-wrapper__row-left">
          <DashboardReportTopDestinations />
        </div>
        <div className="dasshboard-reports-wrapper__row-right">
          <DashboardReportTopMembers />
        </div>
      </div>
    </div >
  );
}
