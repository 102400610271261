export const APP_SELECTOR = '#root';

export const DATE_FORMAT = 'dd MMM yyyy';
export const DATE_FORMAT_ISO = 'yyyy-MM-DDTHH:mm:ss';
export const DATE_FORMAT_COMA = 'dd MMM, yyyy';
export const DATE_FORMAT_NOYEAR_COMA = 'dd MMM';
export const DATE_FORMAT_MONTH = 'MMMM dd, yyyy';
export const DATE_FORMAT_MONTH_START = 'MMM-dd';
export const DATE_FORMAT_MONTH_START_DIFFERENT = 'MMM dd';
export const DATE_FORMAT_MONTH_END = 'MMM-dd, yyyy';
export const DATE_FORMAT_MONTH_END_DIFFERENT = 'dd, yyyy';
export const DATE_FORMAT_CHECK_IN_OUT = 'eee, MMM dd';
export const DATE_FORMAT_CHECK_IN_OUT_YEAR = 'eee, MMM dd, yyyy';
export const DATE_FORMAT_DAY_MONTH = 'dd MMMM';
export const DATE_FORMAT_DAY_MONTH_YEAR = 'dd MMMM yyyy';
export const DATE_FORMAT_UNIT = 'EEEE, d MMMM, yyyy';
export const DATE_FORMAT_UNIT_ROW = 'ddd, D MMMM, yyyy';
export const DATE_FORMAT_FOR_TABLE = 'MM/dd/y';
export const DATE_FORMAT_TRANSACTION = 'dd-MM-yyyy HH:mm';
export const DATE_FORMAT_CANCELED = 'dd-MM-yyyy';
export const DATE_FORMAT_FULL_DATA = 'dd-MM-yyyy, HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MMMM uuuu';
export const DATE_FORMAT_GETAWAY = 'MMMM dd, yyyy';
export const DATE_FORMAT_COMBINATION = 'Pp';

export const DATE_COMPLET_FORMAT_FULL_DATA = 'D MMM yyyy, HH:mm';
export const DATE_COMPLET_FORMAT_FOR_BOOKINGS = 'D MMM yyyy';

export const TIME_FORMAT = 'hh:mm a';
export const DATE_FORMAT_FOR_BOOKINGS = 'MM-dd-yyyy';
export const DATE_FORMAT_FOR_PAYMENT_DETAILS = 'MM-dd-yyyy, h:mm a';
export const TOKEN_HEADER_NAME = 'x-rsiid';
export const DEFAULT_RESIDENCY = 'US';
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_ALLINCLUSIVE_PAGE_SIZE = 6;
export const DEFAULT_SEPARATOR = '&';
export const DEFAULT_KEY_VALUE_SEPARATOR = '=';
export const ROOMS_LABEL = 'rooms';
export const LOCATION_OBJECT_LABEL = 'locationObject';
export const LOCATION_LABEL = 'locationLabel';
export const DATES_LABEL = 'dates';
export const FILTERS_LABEL = 'filters';
export const CONDO_FILTERS_LABEL = 'condoFilters';
export const ROOMS_SEARCH_LABEL = 'roomSearch';
export const ENTER_KEY = 'Enter';
export const SESSION_EXPIRED_STATUS = 404;
export const EMPTY_ROOMS_ARRAY_MESSAGE = 'HotelEntry';
export const DEFAULT_MAP_ZOOM = 14;
export const SESSION_KEY_LABEL = 'sessionKey';
export const SESSION_KEY_LABEL_ROOMS = 'keySessionRooms';
export const CONDO_LOCATIONS_OBJECT_LABEL = 'condoLocationObject';
export const CONDO_LOCATIONS_LABEL = 'condoLocationLabel';
export const CONDO_DATES_LABEL = 'condoDates';
export const CONDO_DATES_FLEXIBLE_LABEL = 'condoFlexibleDates';
export const CONDO_GUESTS_LABEL = 'condoGuests';
export const CONDO_GUESTS_ACTIONS_LABEL = 'condo-guests';
export const CONDO_IS_FLEXIBLE_LABEL = 'condoIsFlexible';
export const CONDO_SESSION_KEY = 'condoSessionKey';
export const CONDO_SEARCH_BOUNDS = 'condoSearchBounds';
export const SESSION_EXPIRATION_DELAY = 60000;
export const GUEST_TYPE_MEMBER = 'member';
export const ERROR_COUPON_STATUS = 400;
export const DEFAULT_GUEST_DATA = {
  givenName: '',
  namePrefix: 'Mr',
  surname: '',
  phone: '',
  email: '',
};
export const VALIDATION_ERROR = 'ValidationError';
export const EXTERNAL_ERROR = 'ExternalError';
export const ROOMS_UNAVAILABLE_ERROR = 'rooms_unavailable';
export const CHECK_OUT_PAYMENT_ERROR = 'check_out_payment';
export const DECLINE_PAYMENT_ERROR = 'provider.credit_card_decline';
export const STATE_PAYMENT_ERROR = 'provider.guestState';
export const POSTAL_CODE_PAYMENT_ERROR = 'person.address.postalCode';
export const RE_CHECK_ERROR = 're_check';
export const PRICE_CHANGED = 'PriceChanged';
export const SAVING_MISMATCH = 'SavingsMismatch';
export const SOLD_OUT = 'SoldOut';
export const ERROR = 'Error';
export const STATUS_OK = 'OK';
export const STATUS_CONFIRMED = 'Confirmed';
export const STATUS_SUCCEEDED = 'Succeeded';
export const STATUS_FAILED = 'Failed';
export const STATUS_PENDING = 'Pending';
export const INVALID_PAYLOAD = 'invalid_payload';
export const CHECKOUT_ERROR = 'checkout_error';
export const HOTEL_ID_ELEMENT = 3;
export const PACKAGE_ID_ELEMENT = 4;
export const WEEKS_PACKAGE_ID_ELEMENT = 5;
export const BOOKING_SUPPLIER_TYPES_LABEL = 'bookingSupplierTypes';
export const BOOKING_SUB_STATUSES_LABEL = 'bookingSubStatuses';
export const BOOKING_TABLE_PAGINATION = 'bookingTablePagination';
export const BOOKING_TABLE_SORT_ORDER = 'bookingTableSortOrder';
export const BOOKING_TABLE_DATE_CHECK_IN_RANGE = 'bookingTableCheckInDateRange';
export const BOOKING_TABLE_DATE_CHECK_OUT_RANGE = 'bookingTableDateCheckOutRange';
export const BOOKING_TABLE_SEARCH = 'bookingTableSearch';
export const MINIMUM_RATING_TO_SHOW = 3;
export const CONDO_BOOKING_GUEST_INFO_LABEL = 'CONDO_BOOKING_GUEST_INFO_LABEL';
export const CONDO_BOOKING_CARD_INFO_LABEL = 'CONDO_BOOKING_CARD_INFO_LABEL';
export const CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL = 'CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL';

export const WEEKS_BOOKING_GUEST_INFO_LABEL = 'WEEKS_BOOKING_GUEST_INFO_LABEL';
export const WEEKS_BOOKING_CARD_INFO_LABEL = 'WEEKS_BOOKING_CARD_INFO_LABEL';
export const WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL = 'WEEKS_BOOKING_SAME_ADDRESS_INFO_LABEL';

export const HOTEL_BOOKING_GUEST_INFO_LABEL = 'HOTEL_BOOKING_GUEST_INFO_LABEL';
export const HOTEL_BOOKING_ADDRESS_INFO_LABEL = 'HOTEL_BOOKING_ADDRESS_INFO_LABEL';
export const HOTEL_SESSION_KEY = 'HOTEL_SESSION_KEY';
export const HOTEL_CONFIRMED_INFO = 'HOTEL_CONFIRMED';
export const HOTEL_BOOKING_COMPLETE = 'HOTEL_BOOKING_COMPLETE';
export const HOTEL_PACKAGE_ID = 'HOTEL_PACKAGE_ID';
export const TRAVEL_LEADERS_DOMAIN = 'https://www.travelleaders.com';
export const PROMO_DETAILS_PATCH = '/index.php?page=promodetails&promoid=';
export const MY_VC_ACCOUNT_LINK = 'https://www.myvcaccount.com/';
export const TUTORIAL_VIDEOS = 'https://suitejourneys.com/welcomevideos';
export const LOCAL_BENEFITS_LINK = 'https://leisurehub.benefithub.com/app/discount-marketplace';
export const MEMBERSHIP_LINK = 'https://www.myvcaccount.com/';
export const CONDO_SPECIALS_LINK = 'https://myvcaccount.com/Condos/Specials';
export const FUNJET_LINK = 'https://www.funjet.com/?plcode=10754866#!/';
export const TRAVEL_GUARD_LINK = 'https://advisors.travelguard.com/buy?ta_arc=248905&pcode=&agencyemail=spotlight%40accessrsi.com';
export const TRAVEL_LEADERS_LINK = 'https://www.travelleaders.com';

export const USD_CURRENCY = 'USD';

export const DISPLAY_HEADER_MESSAGE_LABEL = 'DISPLAY_HEADER_MESSAGE_LABEL';

export const AWARDS = [
  {
    name: 'apple.leisure.group',
    reward: 'elite.diamond.award',
  },
  {
    name: 'viking.cruises',
    reward: 'platinum.circle',
  },
  {
    name: 'princess.cruises',
    reward: 'sales.excellence',
  },
  {
    name: 'care',
    reward: 'sapphire.sponsor',
  },
  {
    name: 'travel.leaders.networks',
    reward: 'presidents.circle',
  },
  {
    name: 'funjet',
    reward: 'club',
  },
];

export enum BackgroundColor {
  dark = 'dark',
  light = 'light',
}

export const NULL_VALUE: any = null;
export const UNDEFINED_VALUE: any = undefined;
