
import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { renderNumber } from '@share/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

export enum HeaderItemType {
  CURRENCY,
  PERCENTAGE,
  NUMBER,
}

export enum HeaderItemColor {
  GREEN,
  BLUE,
}

interface IProps {
  icon: any;
  title: string;
  value: number | undefined;
  type: HeaderItemType;
  color: HeaderItemColor;
}

export function DashboardReportHeaderItem(props: IProps) {
  const { icon, title, value, type, color } = props;

  const getValue = () => {
    if (type === HeaderItemType.CURRENCY) {
      return `$ ${renderNumber(value, 2)}`;
    } else if (type === HeaderItemType.PERCENTAGE) {
      return `${renderNumber(value, 0)} %`;
    } else {
      return renderNumber(value, 0);
    }
  }

  const calculatedValue = useMemo(() => getValue(),[value, type]);

  return (
    <div className="dashboard-report-header-item-wrapper">
      <div className={`dashboard-report-header-item-wrapper__icon ${color === HeaderItemColor.GREEN ? 'green' : 'blue'}`} style={{ width: '47px', height: '47px' }}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="dashboard-report-header-item-wrapper__content" style={{ maxWidth: '91px' }}>
        <label className="content-value">{calculatedValue}</label>
        <label className="content-text"><FormattedMessage id={title} /></label>
      </div>
    </div >
  );
}
