import { AxiosPromise } from 'axios';
import { Toaster, axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';
import { get } from 'lodash';

export const QuotesAPI = {
  get(id: string): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.Quotes}/${id}`, { ...getHeaders() });
  },
};

export enum DownLoadShareType {
  CONDO,
  HOTEL,
  WEEK,
  CAR
}

export const HandleDownload = (type: DownLoadShareType, loading: boolean, data: any, intl: any, onFinish: () => void, onComplete: () => void) => {
  if (!loading) {
    const url = type === DownLoadShareType.WEEK ? Urls.QuotesWeeksDownload : type === DownLoadShareType.CONDO ? Urls.QuotesCondosDownload : Urls.QuotesHontelsDownload;
    axiosInstance.post(url, data, { ...getHeaders(), responseType: 'blob' })
      .then((response) => {
        const headerLine = response.headers['content-disposition'];
        let filename;
        if (headerLine) {
          const startFileNameIndex = headerLine.indexOf('"') + 1
          const endFileNameIndex = headerLine.lastIndexOf('"');
          filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        } else {
          filename = `quote_${new Date().getTime()}_download.pdf`;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        onFinish();
        link.click();
      })
      .catch(async (e) => {
        let error = JSON.parse(await e.response);
        try {
          if (typeof error !== 'string') {
            error = JSON.parse(error);
          }
        } catch(e) {
          console.error(e);
        }

        onFinish();

        const messages = error?.errors;

        if (!!messages?.length) {
          messages.forEach((m: any) => {
            Toaster.error(m?.message);
          });
        } else {
          const msg = error?.reason ? 
                          `Quote send failed: ${error.reason}` : 
                          intl.formatMessage({ id: 'error.code.generic.general' });
          Toaster.error(msg);
        }
      });

      onComplete();
  }
}

export const HandleShare = (
    type: DownLoadShareType,
    loading: boolean,
    data: any,
    intl: any,
    onFinish: () => void,
    onComplete: () => void,
    onFailed: (message: string | undefined) => void) => {
  if (!loading) {
    const url = type === DownLoadShareType.WEEK ? Urls.QuotesWeeksShare : type === DownLoadShareType.CONDO ? Urls.QuotesCondosShare : Urls.QuotesHotelsShare;
    axiosInstance.post(url, data, { ...getHeaders() })
      .then((response) => {
        onFinish();

        if (response.data.members?.length) {
          Toaster.success(`Success. Quote shared to: ${response.data.members.map((x:string) => (x))}`);
        }

        if (response.data.memberFailed?.length) {
          Toaster.error(`Quote failed for: ${response.data.memberFailed.map((x:string) => (x))}`);
        }
      })
      .catch((e) => {
        const error = e.response?.data;

        let errorObject = null;
        try {
          errorObject = JSON.parse(error);
        } catch(e) {
          console.error(e);
        }

        const msgCode = errorObject?.code;
        const messages = errorObject?.errors;

        if (msgCode === 'room_error' && onFailed) {
          const message = get(messages, '[0].message');
          onFailed(message);
        } else {
          onFinish();


          if (!!messages?.length) {
            messages.forEach((m: any) => {
              Toaster.error(m?.message);
            });
          } else {
            const msg = errorObject?.reason ? 
                            `Quote send failed: ${errorObject.reason}` : 
                            intl.formatMessage({ id: 'error.code.generic.general' });
            Toaster.error(msg);
          }
        }
      });
      onComplete();
  }
}
