import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { IGetawayBookingState } from '@store/slices';
import { RootState } from '@share/utils';
import { TravelGuardSvg } from '@assets';
import { TRAVEL_GUARD_LINK } from '@constants';

import './style.scss';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
}

interface IProps extends IMapStateToProps {}

const ZERO = 0;
const DIVIDING_TEXT = 'Availability is extremely';

class GetawayImportantInformationComponent extends React.Component<IProps> {
  getInfoTemplate = () => {
    const { getawayBookingStore } = this.props;
    const { getawayInfo } = getawayBookingStore;
    const infoTemplate = getawayInfo.specialType.importantInfoTemplate;

    return infoTemplate.slice(ZERO, infoTemplate.indexOf(DIVIDING_TEXT));
  };

  render() {
    return (
      <div className="getaway-important-information">
        <p className="getaway-important-information__header secondary-font">
          <FormattedMessage id="important.information" />
        </p>
        <div>
          <p className="getaway-important-information__info-text">{this.getInfoTemplate()}</p>
          <a
            href={TRAVEL_GUARD_LINK}
            target="_blank"
            rel="noreferrer"
            className="getaway-important-information__link"
          >
            <TravelGuardSvg />
          </a>
          <p className="getaway-important-information__info-text">
            <FormattedMessage id="availability.is.extremely.limited" />
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
  };
};

export const GetawayImportantInformation = connect(mapStateToProps)(GetawayImportantInformationComponent);
