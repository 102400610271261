import { useEffect, useState } from "react";
import { getBookingManual, IBookingManualState } from "../../../../share/store/slices/booking-manual";
import { useDispatch, useSelector } from "react-redux";
import ManualBookingForm from "./manual-booking-form/component";
import { FunctionalRootState } from "@share/utils";
import ManualBookingTable from "./manual-booking-table/component";
import { TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import ManualBookingFilters from "./manual-booking-filters/component";
import { IMenuState } from "@share/store/slices";
import { useIntl } from "react-intl";
import moment from "moment";

export interface ManualBookingData {
  manualBookingId: number;
  agentName: string;
  agentID: number;
  contactInformation: string;
  bookingId: number;
  bookingDate: string;
  travelDateStart: string;
  travelDateEnd: string;
  bookingType: string;
  destination: string;
  clientName: string;
  serviceProviderName: string;
  serviceConfirmationNumber: string;
  numberOfTravelers: number;
  detailsOfServices: string;
  totalBookingAmount: string;
  totalCommission: string;
  depositPaid: string;
  paymentStatus: string;
  refundsOrAdjustments: string;
  agentSignature: string;
  dateOfSubmission: string;
  created: string;
  lastUpdated: string;
  pathFile: string;
  userId: string;
  accountId: string;
}

export interface IFiltersManualBooking {
  agentName: string;
  paymentStatus: string;
  bookingId: string;
}

export interface IBookingManualSort {
  sortOrder: string | null, 
  orderBy: string | null
}

export function DashboardManualBooking() {

  const dispatch = useDispatch();
  const bookingManualStore: IBookingManualState = useSelector(
    (state: FunctionalRootState) => state.bookingManualStore,
  );

  const { bookingManual } = bookingManualStore;

  const { items }: IMenuState = useSelector(
    (state: FunctionalRootState) => state.navigationMenuStore,
  );

  const intl = useIntl();

  const agentID = items?.rsiId ?? 0;

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: bookingManual?.totalResults,
  });

  const [sort, setSort] = useState<IBookingManualSort>({sortOrder: 'agentName', orderBy: 'desc'})

  const [filters, setFilters] = useState<IFiltersManualBooking>({
    agentName: '',
    paymentStatus: '',
    bookingId: '',
  });

  useEffect(() => {
    dispatch(getBookingManual(pagination, agentID, sort, filters));
  }, [dispatch, pagination.current, pagination.pageSize, sort])

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: bookingManual?.totalResults,
    }));
  }, [bookingManual?.totalResults]);

  const columns: ColumnsType<ManualBookingData> = [
    {
      title: intl.formatMessage({ id: "bookingmanual.agentName" }),
      dataIndex: "agentName",
      key: "agentName",
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: intl.formatMessage({ id: "bookingmanual.paymentStatus" }),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: intl.formatMessage({ id: "bookingmanual.bookingId" }),
      dataIndex: "bookingId",
      key: "bookingId",
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: intl.formatMessage({ id: "bookingmanual.numberOfTravelers" }),
      dataIndex: "numberOfTravelers",
      key: "numberOfTravelers",
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: intl.formatMessage({ id: "bookingmanual.travelDateStart" }),
      dataIndex: "travelDateStart",
      key: "travelDateStart",
      render: (text) => {
        return moment(text, 'yyyy-MM-DD').format('MM-DD-yyyy');
      },
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: intl.formatMessage({ id: "bookingmanual.travelDateEnd" }),
      dataIndex: "travelDateEnd",
      key: "travelDateEnd",
      render: (text) => {
        return moment(text, 'yyyy-MM-DD').format('MM-DD-yyyy');
      },
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
  ];
  

  const allColumns: ColumnsType<ManualBookingData> = [
    ...columns,
    {
      title: intl.formatMessage({ id: 'bookingmanual.manualBookingId' }),
      dataIndex: 'manualBookingId',
      key: 'manualBookingId',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.contactInformation' }),
      dataIndex: 'contactInformation',
      key: 'contactInformation',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.bookingDate' }),
      dataIndex: 'bookingDate',
      key: 'bookingDate',
      render: (text) => {
        return moment(text, 'yyyy-MM-DD').format('MM-DD-yyyy');
      },
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.bookingType' }),
      dataIndex: 'bookingType',
      key: 'bookingType',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.destination' }),
      dataIndex: 'destination',
      key: 'destination',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.clientName' }),
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.serviceProviderName' }),
      dataIndex: 'serviceProviderName',
      key: 'serviceProviderName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.serviceConfirmationNumber' }),
      dataIndex: 'serviceConfirmationNumber',
      key: 'serviceConfirmationNumber',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.detailsOfServices' }),
      dataIndex: 'detailsOfServices',
      key: 'detailsOfServices',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.totalBookingAmount' }),
      dataIndex: 'totalBookingAmount',
      key: 'totalBookingAmount',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.totalCommission' }),
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.depositPaid' }),
      dataIndex: 'depositPaid',
      key: 'depositPaid',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.refundsOrAdjustments' }),
      dataIndex: 'refundsOrAdjustments',
      key: 'refundsOrAdjustments',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.agentSignature' }),
      dataIndex: 'agentSignature',
      key: 'agentSignature',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.dateOfSubmission' }),
      dataIndex: 'dateOfSubmission',
      key: 'dateOfSubmission',
      render: (text) => {
        return moment(text, 'yyyy-MM-DD').format('MM-DD-yyyy');
      },
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.created' }),
      dataIndex: 'created',
      key: 'created',
      render: (text) => {
        return moment(text, 'yyyy-MM-DD').format('MM-DD-yyyy');
      },
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.lastUpdated' }),
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (text) => {
        return moment(text, 'yyyy-MM-DD').format('MM-DD-yyyy');
      },
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.pathFile' }),
      dataIndex: 'pathFile',
      key: 'pathFile',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.userId' }),
      dataIndex: 'userId',
      key: 'userId',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'bookingmanual.accountId' }),
      dataIndex: 'accountId',
      key: 'accountId',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const [columnsCheck, setColumnsCheck] = useState<ColumnsType<ManualBookingData>>(columns);

  const onSubmitSearch = () => dispatch(getBookingManual(pagination, agentID, sort, filters));
  const onSubmitReset = () => dispatch(getBookingManual(pagination, agentID, sort));

  return (
    <>
      <ManualBookingForm bookingManualStore={bookingManualStore} pagination={pagination} agentID={agentID} filters={filters} sort={sort}/>
      <ManualBookingFilters
        filters={filters}
        setFilters={setFilters}
        onSubmitSearch={onSubmitSearch}
        onSubmitReset={onSubmitReset}
        columns={columns}
        allColumns={allColumns}
        setColumnsCheck={setColumnsCheck}
      />
      <ManualBookingTable
        bookingManualStore={bookingManualStore}
        pagination={pagination}
        setPagination={setPagination}
        columns={columnsCheck}
        setSort={setSort}
      />
    </>
  );
}

